import React, {Component} from 'react'

import {auth} from './helpers/firebase'

import Login from './Login'
import Discounts from './Discounts'
import List from './List'

class App extends Component {
  state = {
    loading: true,
    user: auth.currentUser,
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      this.setState({
        loading: false,
        user,
      })
    })
  }

  handleLogout() {
    auth.signOut()
  }

  render() {
    const {loading, user} = this.state

    if (loading) {
      return <div id="loader"><div id="arc" className="js" /></div>
    }

    if (!user) {
      return <Login />
    }

    return (
      <div>
        <p className="header">
          <span>La Pagaie d’Aure</span>
          <button onClick={this.handleLogout}>Déconnexion</button>
        </p>
        <Discounts />
        <List />
      </div>
    )
  }
}

export default App
