import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CloseIcon from 'react-icons/lib/md/close'

import api from './helpers/api'
import {db} from './helpers/firebase'
import {format} from './helpers/date'

const LANGS = {
  fr: 'français',
  en: 'anglais',
  es: 'espagnol',
}

class Booking extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  state = {
    details: false,
  }

  handleToggle = () => {
    this.setState({
      details: !this.state.details,
    })
  }

  handleDelete = () => {
    const {data} = this.props
    if (window.confirm('Supprimer cette réservation ?')) {
      api.post('unbook', {
        path: data.path,
      })
      .catch((error) => {
        alert(error.message)
      })
    }
  }

  handleSave = (event) => {
    event.preventDefault()
    const {value} = event.target.elements.notes
    db.ref(window.mode + '/' + this.props.data.path + '/notes').set(value)
    this.handleToggle()
  }

  render() {
    const {data} = this.props

    if (this.state.details) {
      const paidAmount = (data.amount / 100).toFixed(2) + ' €'
      const paid = data.charge ? <a href={`https://dashboard.stripe.com/payments/${data.charge}`}>{paidAmount}</a> : paidAmount
      return (
        <li className="inline confirmed">
          <ul>
            <li>Nom : {data.name} {data.lang && `(${LANGS[data.lang]})`}</li>
            <li>Personnes : {data.people}</li>
            {data.email && <li>E-mail : <a href={'mailto:' + data.email}>{data.email}</a></li>}
            {data.phone && <li>Téléphone : <a href={'tel:' + data.phone}>{data.phone}</a></li>}
            {data.referral && <li>Référant : {data.referral}</li>}
            {data.amount > 0 && <li>Payé : {paid} {data.discountCode && `avec le code ${data.discountCode}`}</li>}
            {data.total > 0 && <li>Reste à payer : {((data.total - data.amount) / 100).toFixed(2)} €</li>}
            {data.created_at && <small>Réservation créée le {format(data.created_at)} {data.created_by && `par ${data.created_by}`}</small>}
            <li><button onClick={this.handleDelete}>Supprimer</button></li>
          </ul>
          <form onSubmit={this.handleSave}>
            <textarea name="notes" placeholder="Notes" defaultValue={data.notes} />
            <button type="submit">Enregistrer</button>
          </form>
          <button onClick={this.handleToggle} className="close"><CloseIcon /></button>
        </li>
      )
    }

    return (
      <li>• <button onClick={this.handleToggle} className="link">{data.people}p / {data.name}</button></li>
    )
  }
}

export default Booking
