import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CloseIcon from 'react-icons/lib/md/close'

import api from './helpers/api'

import Booking from './Booking'

const MAX_PEOPLE = 16

const peopleOptions = []
for (let i = 1; i <= MAX_PEOPLE; i++) {
  peopleOptions.push(<option key={i} value={i}>{i} personnes</option>)
}

const langOptions = [
  <option key="fr" value="fr">Français</option>,
  <option key="en" value="en">Anglais</option>,
  <option key="es" value="es">Espagnol</option>,
]

class Book extends Component {
  static propTypes = {
    bookings: PropTypes.object,
    path: PropTypes.string.isRequired,
  }

  state = {
    showForm: false,
    submitting: false,
  }

  handleToggleForm = () => {
    this.setState({
      showForm: !this.state.showForm,
      submitting: false,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const {elements} = event.target
    const name = elements.name.value.trim()
    const spot = elements.spot.value
    const people = Number(elements.people.value)
    const email = elements.email.value.trim() || null
    const phone = elements.phone.value.trim() || null
    const lang = elements.lang.value
    const amount = Math.round(Number(elements.amount.value) * 100)
    const notes = elements.notes.value.trim() || null
    if (name && spot && people) {
      this.setState({
        submitting: true,
      })
      const request = {
        path: this.props.path,
        type: spot,
        name,
        email,
        phone,
        people,
        lang,
        amount,
        notes,
      }
      api.post('book', request)
      .then((res) => {
        if (res.email) {
          alert('Confirmation envoyée à ' + email)
        }
        this.handleToggleForm()
      })
      .catch((error) => {
        alert(error.message)
        this.setState({submitting: false})
      })
    }
  }

  renderForm = () => {
    const {bookings} = this.props

    const spotOptions = [
      <option key="none" value="" />,
      <option key="morning" value="morning" disabled={!!bookings.day}>Matin / classique</option>,
      <option key="afternoon" value="afternoon" disabled={!!(bookings.day || bookings.afternoon_full)}>Après-midi / classique</option>,
      <option key="afternoon_full" value="afternoon_full" disabled={!!(bookings.day || bookings.afternoon)}>Après-midi / intégrale</option>,
    ]
    const defaultSpot = bookings.day ? 'day' : ''

    return (
      <form className="inline book" onSubmit={this.handleSubmit}>
        <label>Nom : <br /><input type="text" name="name" /></label>
        <label>Adresse e-mail : <br /><input type="email" name="email" /></label>
        <label>Téléphone : <br /><input type="text" name="phone" /></label>
        <label>Personnes : <br /><select name="people" defaultValue="2">{peopleOptions}</select></label>
        <label>Créneau : <br /><select name="spot" defaultValue={defaultSpot}>{spotOptions}</select></label>
        <label>Langue : <br /><select name="lang" defaultValue="fr">{langOptions}</select></label>
        <label>Payé : <br /><input type="number" min="0" step="0.01" name="amount" defaultValue="0" /></label>
        <label>Notes : <br /><textarea name="notes" /></label>
        <button type="submit" disabled={this.state.submitting}>Enregistrer</button>
        <button onClick={this.handleToggleForm} className="close"><CloseIcon /></button>
      </form>
    )
  }

  render() {
    const {bookings} = this.props

    const mapper = (data, index) => <Booking data={data} key={index} />

    const content = []
    if (bookings.morning) {
      content.push(<ul key="morning"><h3>Matin : descente classique</h3>{bookings.morning.map(mapper)}</ul>)
    }
    if (bookings.afternoon) {
      content.push(<ul key="afternoon"><h3>Après-midi : descente classique</h3>{bookings.afternoon.map(mapper)}</ul>)
    }
    if (bookings.afternoon_full) {
      content.push(<ul key="afternoon"><h3>Après-midi : descente intégrale</h3>{bookings.afternoon_full.map(mapper)}</ul>)
    }
    if (!content.length) {
      content.push(<p key="none">Aucune réservation</p>)
    }

    return (
      <div className="book">
        {content}
        {this.state.showForm ? this.renderForm() : <button onClick={this.handleToggleForm}>Ajouter une réservation</button>}
      </div>
    )
  }
}

export default Book
