import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'

window.mode = (window.localStorage && localStorage.getItem('test')) ? 'test' : 'live'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
