import {auth} from './firebase'

const post = (route, params) => auth.currentUser.getIdToken()
.then((token) => {
  params.mode = window.mode
  return fetch(process.env.REACT_APP_API + '/' + route, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify(params),
  })
})
.then((response) => {
  if (response.status >= 500) {
    throw new Error('HTTP ' + response.status + ': ' + response.statusText)
  }
  // if status >= 400, the error will be in the returned JSON and is handled by the component
  return response.json()
})
.then((response) => {
  if (response.error) {
    throw new Error(response.error)
  }
  return response
})

export default {post}
