import React, {Component} from 'react'

import Avail from './Avail'
import Book from './Book'

import {db} from './helpers/firebase'

const MONTH_START = 1 // February
const MONTH_END = 7 // August

const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
const spots = ['morning', 'afternoon', 'afternoon_full']

const pad = (input) => {
  const str = String(input)
  if (str.length < 2) {
    return '0' + str
  }
  return str
}

class List extends Component {
  state = {
    data: null,
  }

  componentDidMount() {
    db.ref(window.mode).on('value', (snapshot) => {
      this.setState({
        data: snapshot.val() || {},
      })
    })
  }

  componentWillUnmount() {
    db.ref(window.mode).off('value')
  }

  now = new Date()
  year = this.now.getFullYear()

  render() {
    const {data} = this.state

    if (!data) {
      return <div id="loader"><div id="arc" className="data" /></div>
    }

    const yearBookings = data.bookings ? (data.bookings[this.year] || {}) : {}
    const yearEvents = data.events ? (data.events[this.year] || {}) : {}
    const yearCalendar = data.calendar ? (data.calendar[this.year] || {}) : {}

    const startMonth = Math.max(MONTH_START, this.now.getMonth())

    const blocks = []
    for (let month = startMonth; month <= MONTH_END; month++) {
      const monthStr = pad(month + 1)
      const monthBookings = yearBookings[monthStr] || {}
      const monthEvents = yearEvents[monthStr] || {}
      const monthCalendar = yearCalendar[monthStr] || {}
      const daysInMonth = new Date(this.year, month + 1, 0).getDate()

      let startDay = 1
      if (month === this.now.getMonth()) {
        startDay = this.now.getDate()
      }

      const lines = []
      for (let day = startDay; day <= daysInMonth; day++) {
        const dayStr = pad(day)
        const date = new Date(this.year, month, day)
        const path = this.year + '/' + monthStr + '/' + dayStr
        const bookings = monthBookings[dayStr] || {}
        const events = monthEvents[dayStr] || {}
        const calendar = monthCalendar[dayStr]

        const lists = {}
        spots.forEach((spot) => {
          if (bookings[spot] || events[spot]) {
            lists[spot] = []
          }
          if (bookings[spot]) {
            for (const key in bookings[spot]) {
              bookings[spot][key].path = 'bookings/' + path + '/' + spot + '/' + key
              lists[spot].push(bookings[spot][key])
            }
          }
          if (events[spot]) {
            for (const key in events[spot]) {
              events[spot][key].path = 'events/' + path + '/' + spot + '/' + key
              lists[spot].push(events[spot][key])
            }
          }
        })

        lines.push(
          <div className="day" key={'d' + day}>
            <h2>{days[date.getDay()]} {day} {months[month]}</h2>
            <Avail calendar={calendar} path={path} />
            <Book bookings={lists} path={path} />
          </div>
        )
      }

      blocks.push(
        <div className="month" key={'m' + month}>
          {lines}
        </div>
      )
    }

    return <div id="list">{blocks}</div>
  }
}

export default List
