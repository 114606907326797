import React, {Component} from 'react'

import {db} from './helpers/firebase'

class Discounts extends Component {
  state = {
    discounts: null,
  }

  componentDidMount() {
    db.ref('discounts').on('value', (snapshot) => {
      this.setState({
        discounts: snapshot.val() || {},
      })
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const {elements} = event.target
    const code = elements.code.value.trim().toUpperCase()
    const percent = Number(elements.percent.value)
    if (code && percent) {
      db.ref('discounts/' + code).set({
        percent,
      })
      elements.code.value = ''
      elements.percent.value = ''
    }
  }

  handleDelete = (event) => {
    const code = event.target.getAttribute('data-code')
    if (window.confirm('Supprimer ce code ?')) {
      db.ref('discounts/' + code).remove()
    }
  }

  render() {
    const {discounts} = this.state

    if (!discounts) {
      return null
    }

    const list = []
    for (const code in discounts) {
      list.push(<li key={code}>{code} : {discounts[code].percent}% <button onClick={this.handleDelete} data-code={code}>Supprimer</button></li>)
    }

    return (
      <div className="discounts">
        <h2>Codes promo</h2>
        <ul>{list}</ul>
        <form onSubmit={this.handleSubmit}>
          <input type="text" name="code" placeholder="Code" />
          <input type="number" name="percent" min="1" max="100" step="1" />%
          <button type="submit">Ajouter</button>
        </form>
      </div>
    )
  }
}

export default Discounts
