import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CloseIcon from 'react-icons/lib/md/close'

import api from './helpers/api'

const PARTNERS = ['avajan']

const partnerOptions = PARTNERS.map((partner) => <option key={partner} value={partner}>Réservé à {partner}</option>)
partnerOptions.unshift(<option key="open" value="open">Ouvert</option>)
partnerOptions.push(<option key="closed" value="closed">Fermé</option>)

class Avail extends Component {
  static propTypes = {
    calendar: PropTypes.object,
    path: PropTypes.string.isRequired,
  }

  state = {
    showForm: false,
    split: !!(this.props.calendar && !this.props.calendar.day),
    loading: false,
    submitting: false,
  }

  componentWillReceiveProps(props) {
    if (this.state.split && props.calendar && props.calendar.day) {
      this.setState({
        split: false,
      })
    } else if (!this.state.split && props.calendar && !props.calendar.day) {
      this.setState({
        split: true,
      })
    }
  }

  handleToggleForm = () => {
    this.setState({
      showForm: !this.state.showForm,
      submitting: false,
    })
  }

  handleSplit = (event) => {
    this.setState({
      split: event.target.value === 'halfday',
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const {elements} = event.target

    const request = {
      path: this.props.path,
    }

    this.setState({
      submitting: true,
    })

    if (elements.split.value === 'halfday') {
      request.morning = elements.morning.value
      request.afternoon = elements.afternoon.value
    } else {
      request.day = elements.day.value
    }

    api.post('availability', request)
    .then(this.handleToggleForm)
    .catch((error) => {
      alert(error.message)
      this.setState({submitting: false})
    })
  }

  renderForm = () => {
    const {calendar} = this.props

    const getValue = (spot) => {
      if (calendar && calendar[spot]) {
        if (calendar[spot].closed) {
          return 'closed'
        } else if (calendar[spot].partner) {
          return calendar[spot].partner
        }
      }
      return 'open'
    }

    const {split, submitting} = this.state

    const content = []
    if (split) {
      content.push(<label key="morning">Matin : <br /><select name="morning" defaultValue={getValue('morning')}>{partnerOptions}</select></label>)
      content.push(<label key="afternoon">Après-midi : <br /><select name="afternoon" defaultValue={getValue('afternoon')}>{partnerOptions}</select></label>)
    } else {
      content.push(<label key="day"><select name="day" defaultValue={getValue('day')}>{partnerOptions}</select></label>)
    }

    const fullday_disabled = !!((calendar && calendar.morning && calendar.morning.booked) || (calendar && calendar.afternoon && calendar.afternoon.booked))
    const halfday_disabled = !!(calendar && calendar.day && calendar.day.booked)

    return (
      <form className="inline avail" onSubmit={this.handleSubmit}>
        <label className="line">
          <input
            type="radio"
            name="split"
            value="fullday"
            onChange={this.handleSplit}
            checked={!split}
            disabled={fullday_disabled}
          /> Journée
        </label>
        <label className="line">
          <input
            type="radio"
            name="split"
            value="halfday"
            onChange={this.handleSplit}
            checked={split}
            disabled={halfday_disabled}
          /> Matin/après-midi
        </label>
        {content}
        <button type="submit" disabled={submitting}>Enregistrer</button>
        <button onClick={this.handleToggleForm} className="close"><CloseIcon /></button>
      </form>
    )
  }

  render() {
    if (this.state.showForm) {
      return this.renderForm()
    }

    const {calendar} = this.props
    const content = []

    if (calendar) {
      if (calendar.day) {
        if (calendar.day.closed) {
          content.push(<p key="day closed" className="closed">Fermé toute la journée</p>)
        } else if (calendar.day.partner) {
          content.push(<p key="day partner" className="partner">Réservé à {calendar.day.partner}</p>)
        } else {
          content.push(<p key="day open" className="open">Disponible à la journée</p>)
        }
      } else {
        if (calendar.morning) {
          if (calendar.morning.closed) {
            content.push(<p key="morning closed" className="closed">Fermé le matin</p>)
          } else if (calendar.morning.partner) {
            content.push(<p key="morning partner" className="partner">Matin réservé à {calendar.morning.partner}</p>)
          } else {
            content.push(<p key="morning open" className="open">Disponible le matin</p>)
          }
        } else {
          content.push(<p key="morning open" className="open">Disponible le matin</p>)
        }
        if (calendar.afternoon) {
          if (calendar.afternoon.closed) {
            content.push(<p key="afternoon closed" className="closed">Fermé l’après-midi</p>)
          } else if (calendar.afternoon.partner) {
            content.push(<p key="afternoon partner" className="partner">Après-midi réservé à {calendar.afternoon.partner}</p>)
          } else {
            content.push(<p key="afternoon open" className="open">Disponible l’après-midi</p>)
          }
        } else {
          content.push(<p key="afternoon open" className="open">Disponible l’après-midi</p>)
        }
      }
    } else {
      content.push(<p key="open" className="open">Disponible</p>)
    }

    return (
      <div className="clickable" onClick={this.handleToggleForm}>
        {content}
      </div>
    )
  }
}

export default Avail
